
  import { Component, Watch } from 'vue-property-decorator'
  import Cell from './cell'
  import { mapActions } from 'vuex'
  import { FileProcess } from '@/entities/files'
  import FormTitle from '@/components/forms/FormTitle.vue'
  import AutoResume from '@/components/forms/AutoResume.vue'
  import { FinancialAccount, Payment } from '@/entities/finance'
  import { plainToInstance } from 'class-transformer'
  import { fixPrice } from '@/utils/general'
  import Attachment from '@/components/toolkit/details/row/custom/Attachment.vue'
  import { IProcessAttachment } from '@/entities'
  import ExpenseDialog from './ExpenseDialog.vue'

@Component({
  components: {
    FormTitle,
    AutoResume,
    Attachment,
    ExpenseDialog,
  },
  methods: {
    ...mapActions('resources/form', ['fetchData']),
  },
})
  export default class BackupExpenseCell extends Cell {
  link: string = ''
  files: FileProcess[] = []
  dialog: boolean = false
  expense = plainToInstance(Payment, {})
  financialAccount = plainToInstance(FinancialAccount, {})
  processAttachment: IProcessAttachment[] = []
  fixPrice = fixPrice

  toggleDialog () {
    this.dialog = !this.dialog
  }

  @Watch('expense', { immediate: true, deep: true })
  async updateAccount () {
    this.financialAccount = (await this.fetchData({
      query: { name: 'find', model: 'FinancialAccount' },
      filter: { id: { _eq: this.expense?.financialAccount?.id } },
    }))[0]
  }

  async mounted () {
    const { item, options } = this

    if (!item?.id || !options?.name) return

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'payment' } },
    })

    this.expense = item
    this.expense.deal.auto.attributes = (await this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute' },
      filter: {
        id_deal: { _eq: this.expense.deal.id },
      },
    }))[0]
    this.expense.deal.auto.maintenances = await this.fetchData({
      query: { name: 'find', model: 'Maintenance' },
      filter: {
        id_auto: { _eq: this.expense.deal.auto.id },
      },
    })

    const paymentOrderProcess = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { id: { _eq: this.expense?.paymentRecipient?.paymentOrder?.idProcess } },
    }))[0]

    this.processAttachment = [
      {
        idProcessRecord: this.expense?.paymentRecipient?.paymentOrder?.idProcessRecord,
        tableName: paymentOrderProcess.tableName,
      },
      {
        idProcessRecord: this.expense.id,
        tableName: 'payment',
        allowedFiles: [
          'expense_payment',
          'spending_support',
        ],
      },
    ]

    this.files = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: item.id } },
          {
            parameter: {
              _and: [
                { process: { id: { _eq: process[0].id } } },
                { name: { _eq: options?.name } },
              ],
            },
          },
        ],
      },
      force: true,
    })
  }

  openFiles (files) {
    files.map(file => {
      this.openLink(file.file.uri)
    })
  }

  get status () {
    if (!this.expense) return
    if (this.expense?.status?.status?.name === 'closed') {
      return this.expense?.closingReason
    } else {
      return this.expense?.status
    }
  }

  get dialogData () {
    const { expense, status, financialAccount, toggleDialog, processAttachment } = this
    return {
      expense,
      status,
      financialAccount,
      toggleDialog,
      processAttachment,
    }
  }
  }
